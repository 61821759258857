import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 14,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “We are so happy We Rock the Spectrum came to Franklin! This is a great place to bring your kids - lots to do. The staff are friendly and caring.”
                            </p>
                            <p className="overview">Mark S.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This place is amazing!!! Great set up, clean and organized, and wonderful staff! My daughter hasn’t quit asking to go back.”
                            </p>
                            <p className="overview">Amber K.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “A place where our little guy could be himself. The staff was extremely interactive and clearly loved what they do. Very family oriented which made it so much better. The owner was extremely friendly and played with all the kiddos. If I could give this place more than 5 stars I would! It’s truly a place where you never have to say sorry! We can’t wait to go back!"
                            </p>
                            <p className="overview">
                            Bekah H.
                            </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                                “Loved the place, the set up and staff. Really looking forward going again. Thank you so much for opening such amazing place."
                            </p>
                            <p className="overview">
                            Sanela C.
                            </p>
                        </div> 
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “The community needed this!!  Very thankful for this inclusive & sensory friendly gym for my boy.”
                            </p>
                            <p className="overview">Stacy R.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This place is amazing!!! Great set up, clean and organized, and wonderful staff! My daughter hasn’t quit asking to go back.”
                            </p>
                            <p className="overview">Susan S.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Great space for all kids to play."
                            </p>
                            <p className="overview">
                            Jackson L.
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “My family is beyond happy that We Rock the Spectrum is now open in Franklin. We’ve met the owners and they’re good people. Our children have already fallen in love with the place and always ask about going back. WTRS is not just a cool indoor playground with a zipline, rock wall and other fun activities. It’s a space intentionally designed so that kids of all abilities can be safe while having a lot of fun."
                            </p>
                            <p className="overview">
                            Paul K.
                            </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                                “We were having an extremely unexpected and emotional morning. I knew I had to get my daughter and 2 grand dolls out to get their minds off of things. WRTS did exactly that! It was everything we needed and more! Not only did the girls have a blast and didn't want to leave, the staff and Steve are phenomenal!! This will definitely be our "go to" play place!! You won't be sorry you went!! Can't wait to go back."
                            </p>
                            <p className="overview">
                            Painting & Pigtails
                            </p>
                        </div> 
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We LOVE We Rock! It’s a great place for kids of all ages!”
                            </p>
                            <p className="overview">Megan F.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This place has something for everyone. The owner is accommodating and very welcoming. We had my nephews birthday here and it was the perfect set up! What a fabulous idea for a kids gym.”
                            </p>
                            <p className="overview">Kelli H.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Amazing time! So happy to have this in our community!"
                            </p>
                            <p className="overview">
                            Devon L.
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We Rock the Spectrum Kids Gym is amazing for place for kids on the spectrum. They have a big trampoline, several small ones, lots of swings, a zip line, a climbing dome, some slides, a climbing wall, and a rope climber. There is an arts and crafts section and a place for parents to camp out while the kids run around. There is also a calming room. The staff are very polite and helpful with keeping the kids on track. No issues with parking. Plenty of it at this center. Pricing for the gym is reasonable since you get so much for what you pay. I highly recommend coming here for kids fun. They have pricing packages that will save you money over time too."
                            </p>
                            <p className="overview">
                            Tim H.
                            </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                                “Amazing place! My son walked right there today and in spite of how busy they were for their grand opening he instantly felt comfortable and welcome. We stayed for about an hour in which time he tried all the swings, the trampoline, the rock wall, and thought about the zipline but couldn't quite get up the courage (I'm sure he will soon though). I highly recommend this place to anyone with autistic kids or sensory kids because it's amazing!"
                            </p>
                            <p className="overview">
                            Leslee W.
                            </p>
                        </div> 
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
